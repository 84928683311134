import React from "react";
import SEO from "./SEO";
import { Link } from "gatsby";
import Logo from "../components/Logo";
import Footer from "./Footer";

const Layout = (props) => {
    return (
        <>
            <SEO />
            <div className={`flex flex-col ${props.index && "bg-pl bg-no-repeat bg-center bg-cover h-screen"}`}>
                {!props.index && (
                    <header className="flex align-middle justify-center py-3 mb-1 md:mb-10 text-white bg-black">
                        <div className="mx-auto">
                            <Link to="/">
                                <Logo dark />
                            </Link>
                        </div>
                    </header>
                )}
                {props.children}
                <Footer index={props.index} />
            </div>
        </>
    );
};

export default Layout;
