import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

const Logo = () => (
    <StaticQuery
        query={graphql`
            query {
                file(relativePath: { eq: "logo-passoladro-payoff.png" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        # Makes it trivial to update as your page's design changes.
                        fixed(width: 120) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
            }
        `}
        render={(data) => (
                <Img
                    fixed={data.file.childImageSharp.fixed}
                    // objectFit="cover"
                    // objectPosition="50% 50%"
                    alt="Logo"
                />
        )}
    />
);

export default Logo;
