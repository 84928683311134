import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../hooks/useSiteMetadata";

const SEO = () => {
    const { title, description } = useSiteMetadata();

    return (
        <Helmet>
            <html lang="it" />
            <meta charSet="utf-8" />
            <meta name="robots" content="noindex" />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta name="facebook-domain-verification" content="0yyqcf25ncac79e4pmfflbo6jqe25w" />
            <title>{title}</title>
        </Helmet>
    );
};

export default SEO;
